<div class="bg-[#0F1A2B] py-[63px] relative">
    <div class="container mx-auto">
        <div class="grid grid-cols-4 max-sm:gap-x-[4px] max-lg:gap-x-[74px] max-lg:grid-cols-2 max-lg:gap-y-10 max-md:grid-cols-1">
            <div class="flex justify-center">
                <a [routerLink]="''" [rel]="'dofollow'">
                    <img src="assets/images/global/logo_footer.svg" alt="logo Pion Vietnam" title="logo Pion Vietnam" />
                </a>
            </div>
            <div class="border-l border-white/40 border-solid pl-9 py-2">
                <nav class="flex items-center h-full">
                    <ul class="flex flex-col gap-y-4 w-full list-disc">
                        @for (item of menus; track $index) {
                        <li class="text-white text-sizeDefault font-medium duration-300 ease-in-out hover:text-colorActive"
                            [routerLinkActive]="['is-active']" [routerLinkActiveOptions]="{
                            exact: true
                        }">
                            <a class="  block w-full h-full " [routerLink]="item.link">
                                {{item.title}}
                            </a>
                        </li>
                        }

                    </ul>
                </nav>
            </div>
            <div class="">
                <ul class="flex flex-col gap-y-4 border-l border-white/40 border-solid pl-4 py-2 relative top-1/2 -translate-y-1/2">
                    @for (item of contactsInfo; track $index) {
                    <li class="flex items-center gap-x-[10px]">
                        <img [src]="item.icon" width="24" height="24" alt="" />
                        <a [href]="item.link" class="text-white text-sizeDefault font-medium">{{item.title}}</a>
                    </li>
                    }
                </ul>
            </div>
            <div class="flex items-center ml-4 max-md:mx-auto">
                <iframe
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprofile.php%3Fid%3D100089142523230&tabs=timeline&width=292&height=132&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=287244537376508"
                    width="292" height="132" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
                    allowfullscreen="true"
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
        </div>
    </div>
</div>