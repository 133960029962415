<div id="header" class="h-20 max-lg:h-14">
    <div [ngClass]="showScroll ? 'header-up' : 'header-down'" class="py-3 fixed top-0 w-full z-[999] bg-white max-lg:py-[8px]"
        style="box-shadow: 0px 4px 8px 0px #00000014;">
        <div class="h-full flex items-center justify-between container mx-auto px-10 max-lg:px-[15px]">
            <div class="header-logo">
                <a [routerLink]="['']" [rel]="'dofollow'">
                    <img src="assets/images/global/logo.svg" alt="Pion Vietnam" title="Pion Vietnam" width=""
                        height="" class="max-lg:h-[38px]"/>
                </a>
            </div>
            <div class="max-lg:hidden">
                <nav id="nav">
                    <ul id="navigation" class="flex items-center justify-center gap-x-6">
                        @for (item of menu; track $index) {
                            <!-- @if(item.link.indexOf( '/tuyen-dung' ) >= 0) {
                                <li>
                                    <a class="text-linkHeader font-semibold py-2 px-[22px] block w-full h-full ease-in duration-300 hover:text-colorActive"
                                        [routerLinkActiveOptions]="{exact: false}" 
                                        [routerLinkActive]="['is-active']" 
                                        [routerLink]="item.link">
                                        {{item.title }}
                                    </a>
                                </li>
                            } @else {
                                <li>
                                    <a class="text-linkHeader font-semibold py-2 px-[22px] block w-full h-full ease-in duration-300 hover:text-colorActive"
                                        [routerLinkActiveOptions]="{exact: true}" 
                                        [routerLinkActive]="['is-active']" 
                                        [routerLink]="item.link">
                                        {{item.title }}
                                    </a>
                                </li>
                            } -->
                            <li>
                                <a class="text-linkHeader font-semibold py-2 px-[22px] block w-full h-full ease-in duration-300 hover:text-colorActive"
                                    [routerLinkActiveOptions]="{exact: true}" 
                                    [routerLinkActive]="['is-active']" 
                                    [routerLink]="item.link">
                                    {{item.title }}
                                </a>
                            </li>
                        
                        }
                    </ul>
                </nav>
            </div>
            <div class="max-lg:hidden">
                <ng-container [ngTemplateOutlet]="locale"></ng-container>
            </div>
            <button type="button" class="hidden max-lg:block">
                <div class="cursor-pointer w-[40px] h-[40px] flex items-center justify-center"
                (click)="openTabMenu()"
                [ngClass]="{ change: this.openMenuMobile }">
                    <img src="assets/images/global/ic_setting_menu.svg" alt="" title="" /></div>
            </button>
        </div>
    </div>

</div>

<div #menuMobile class="menu-mobile">
    <div (click)="openTabMenu()" class="close-menu-mobile">
        <span class="material-symbols-outlined"> cancel </span>
        <p class="text-white">Đóng danh sách</p>
    </div>
    <nav id="nav-mobile">
        <ul id="navigation-mobile" class="flex flex-col">
            @for (item of menu; track $index) { 
                <!-- @if(item.link.indexOf(
            '/tuyen-dung' ) >= 0){
            <li class="text-base capitalize max-sm:text-sm">
                <a class="px-4 py-4 block max-2xl:px-2" [routerLink]="item.link" [routerLinkActiveOptions]="{
                        exact: false
                    }" [routerLinkActive]="['is-active']" (click)="closeTab()">{{ item.title }}
                </a>
            </li>
            } @else {
            <li class="text-base capitalize max-sm:text-sm">
                <a class="px-4 py-4 block max-2xl:px-2" [routerLink]="item.link" [routerLinkActiveOptions]="{
                        exact: true
                    }" [routerLinkActive]="['is-active']" (click)="closeTab()">{{ item.title }}
                </a>
            </li>
            } -->
            <li class="text-base capitalize max-sm:text-sm">
                <a class="px-4 py-4 block max-2xl:px-2" [routerLink]="item.link" [routerLinkActiveOptions]="{
                        exact: true
                    }" [routerLinkActive]="['is-active']" (click)="closeTab()">{{ item.title }}
                </a>
            </li>
         }
        </ul>
    </nav>
    <div class="border-t border-[#E1E1E1] border-solid mx-4">
        <div class="block">
            <button type="button" class="font-semibold text-base py-4 mr-8"
                [ngClass]="this.localeDefaultService() == 'vi' ? 'locale-active' : 'locale'"
                (click)="setLocale('vi')">VN</button>
            <button type="button" class="font-semibold text-base py-4"
                [ngClass]="this.localeDefaultService() == 'en' ? 'locale-active' : 'locale'"
                (click)="setLocale('en')">EN</button>
        </div>
    </div>
</div>

<ng-template #locale>
    <div class="flex items-center justify-center gap-x-2">
        <button type="button" class="text-linkHeader font-semibold py-2 px-[14.5px] block w-full h-full"
            [ngClass]="this.localeDefaultService() == 'vi' ? 'locale-active' : 'locale'"
            (click)="setLocale('vi')">VN</button>
        <button type="button" class="text-linkHeader font-semibold py-2 px-[14.5px] block w-full h-full"
            [ngClass]="this.localeDefaultService() == 'en' ? 'locale-active' : 'locale'"
            (click)="setLocale('en')">EN</button>
    </div>
</ng-template>
